$sidebar-bg-color: #1e2833 !default;
$sidebar-color: #adadad !default;
$sidebar-width: 180px !default;
$sidebar-collapsed-width: 70px !default;
$highlight-color: #d8d8d8 !default;
$submenu-bg-color: #1e2833 !default;
$submenu-bg-color-collapsed: #1e2833 !default;
$icon-bg-color: #1e2833 !default;
$icon-size: 35px !default;
$submenu-indent: 48px !default;
$breakpoint-xs: 480px !default;
$breakpoint-sm: 576px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 992px !default;
$breakpoint-xl: 1200px !default;
$breakpoint-xxl: 1600px !default;

@import '~react-pro-sidebar/dist/scss/styles.scss';

.sideBar {
    font-family: sans-serif;
    height: 100vh;
    //width: 180px;
    position: fixed;
    bottom: 0;
    left: 0;
    top: 48px;
    background-color: #1e2833;
    z-index: 999;
  }

.pro-sidebar {
  .pro-menu {
    > ul {
      > .pro-sub-menu {
        > .pro-inner-list-item {
          > div > ul {
            padding-top: 0px;
            padding-bottom: 10px;
          }
        }
      }
    }
    .pro-menu-item {
      > .pro-inner-item {       
        padding: 2px 35px 2px 20px;
      }
      &.pro-sub-menu {
        .pro-inner-list-item {
          .pro-inner-item {
            padding: 4px 30px 2px 15px;
          }
        }
      }
    }
  }
}