.mainContent {
  text-align: center;
  /* overflow: auto; */
  margin-left: 180px;
  padding:2em;
  padding-top: 2.6em;
  background-color: #273442;
  /* height: 91.2vh; */
  color: #cacaca;
  }

.topBar {
  /* position: -webkit-sticky;
  position: sticky; */
  position: fixed;
  overflow: hidden;
  top: 0;
  z-index: 999;
  width: 100%;
  background-color: #1e2833 !important;
}